/**
 * Функциональность сайта. Сохранение ссылок в
 * одном объекте позволяет лучше контролировать процессы.
 * А также управлять жизненным циклом приложений.
 *
 * примеры можно посмотреть в `src/platform/index.ts`
 */
import {
  FeedbackForm,
  FeedbackFormTrigger
} from '@/site/components/feedback'
import { start as startSimpleTabs } from '@/site/components/simple-tabs'
import { PlatformBase } from "@/platform/platform";

 export class Site extends PlatformBase {
    constructor() {
      super()
      this.registerComponents([
      { name: 'feedback-form', component: FeedbackForm}
    ])

      this.mount()
    }

    mount(): void {
      this.collection['feedbackForm'] = this.mountComponent('#site-feedback-form', FeedbackForm)
      this.collection['feedbackTriggers'] = this.mountAsTrigger('.js-feedback-trigger', 'FeedbackFormTrigger', FeedbackFormTrigger)

      /**
       * Табы на страницах продуктов
       */
      this.collection['product-page-tabs'] = startSimpleTabs('product-page-tabs')
    }
 }
