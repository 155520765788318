import Vue from 'vue'

/**
 * Tabs. Инициализация элемента на станице. Требуется указывать
 * идентификатор элемента. Так инициализация будет проще и понятней.
 *
 * Разметка табов создается сразу на странице.
 *
 * Для каждой отдельной версии табов на странице необходимо вызывать
 * отдельно инициализацию. Идентификатор корневого элемента передается
 * без символа `#`.
 *
 * start('my-tabs')
 *
 * Пример разметки - `/demos/tabs/`
 * Пример инициализации - `src/platform/index.ts`
 *
 * @see https://buefy.org/documentation/tabs/
 * @param selector идентификатор элемента на странице
 *
 */
export function start (selector = 'simple-tabs'): Vue|undefined {
  const element: HTMLElement|null = document.getElementById(selector)

  if (!element) return

  return new Vue({
    el: element,
    data () {
      return {
        activeTab: 0
      }
    }
  })
}
