<template>
  <div class="site-feedback-form">
    <validation-observer v-slot="{invalid, handleSubmit}" ref="observer">

      <div class="form-messages" ref="messages">
        <b-notification type="is-danger" @close="onCloseNotification" has-icon="" v-model="status.error">
          <p>Ошибка! Не удалось отправить сообщение.</p>
          <p>Попробуйте снова, если ошибка повторяется, обновите страницу.</p>
          <template v-if="showExtendedError">
            <p>Описание ошибки:</p>
            <span v-for="(item, index) in errors" :key="index">
              <strong>{{index}}: {{item}}</strong>
            </span>
          </template>
        </b-notification>
      </div>

      <b-notification type="is-success" @close="onCloseNotification" has-icon="" v-model="status.success">
        <p>Спасибо! Ваше сообщение отправлено.</p>
        <p>Постараемся ответить как можно быстрее.</p>
      </b-notification>

      <div class="form-wrapper" v-if="isNotSuccess">
        <form @submit.prevent.stop="handleSubmit(submit)" class="form" ref="form" enctype="multipart/form-data">
          <input type="hidden" name="WEB_FORM_ID" :value="formId">

          <validation-provider v-slot="v" name="Ваше имя" rules="required" slim>
            <p class="has-text-danger is-size-7">{{v.errors[0]}}</p>
            <b-field  :type="{ 'is-danger': v.invalid && v.dirty }" class="mb-3">
              <b-input name="form_text_41" placeholder="Ваше имя*" v-model="formData.name"></b-input>
            </b-field>
            
          </validation-provider>

          <validation-provider v-slot="v"  name="Телефон" slim>
            <p class="has-text-danger is-size-7">{{v.errors[0]}}</p>
            <b-field class="mb-3">
              <b-input name="form_text_38" placeholder="Контактный телефон" v-model="formData.phone"></b-input>
            </b-field>
            
          </validation-provider>

          <validation-provider v-slot="v" rules="required|email" name="Email" slim>
            <p class="has-text-danger is-size-7">{{v.errors[0]}}</p>
            <b-field   :type="{ 'is-danger': v.invalid && v.dirty }" class="mb-3">
              <b-input name="form_email_2" placeholder="Email *" v-model="formData.email"></b-input>
            </b-field>
            
          </validation-provider>

          <validation-provider v-slot="v" rules="required|max:2000" slim name="Сообщение">
            <p class="has-text-danger is-size-7">{{v.errors[0]}}</p>
            <b-field  type="{'is-danger': v.invalid}" class="mb-3">
              <b-input name="form_textarea_1" placeholder="Сообщение *" type="textarea" v-model="formData.message"></b-input>
            </b-field>
            
          </validation-provider>


          <b-message style="margin-top: 2rem;" class="is-light">
            <p class="help">
              Отправляя сообщение вы принимаете <a target="_blank" href="">Положение на обработку персональных данных и получение информации.</a>. Если вы не
              согласны <a target="_blank" href="">с условиями
              положения</a>, не отправляйте форму.
              Ваши данные будут использованы только для связи с вами, для ответа на ваше сообщение.
            </p>
          </b-message>

          <div class="controls">
            <b-button type="is-warning is-fullwidth" native-type="submit" :disabled="invalid" :class="{'is-loading': isLock}">отправить</b-button>
          </div>
        </form>

      </div>
    </validation-observer>
  </div>
</template>
<script>
import Vue from 'vue'
import FormCommonMixin from '@/mixins/form-common-mixin'

export default Vue.extend({
  name: 'feedback-form',
  mixins: [FormCommonMixin],
  data() {
    return {
      formId: 1,
    }
  },
  methods: {
    /**
     * при рекурсивных ссылках
     * больше проблем чем пользы
     *
     * - <MountingPortal /> вызывается каждый раз при открытии формы
     * - Надо задавать имя и рег. компонент глобально
     *
     * @todo это только пример (НЕ использовать)
     */
    // click() {
    //   this.$buefy.modal.open({
    //     component: 'feedback-form'
    //   })
    // }
  }
})
</script>

<style lang="sass" scoped>
@import 'helpers'
.site-feedback-form
  //background: #fff
.form-header
  margin-bottom: 1rem
  padding-bottom: 1rem
  border-bottom: 1px solid #eee
  background: #fff
/** in modal  */
.form-in-modal .site-feedback-form
  .form-wrapper
    background: #fff
    padding: 2rem
  .form-header
    background: $primary
    p.title
      padding-left: 2rem
      padding-top: 1rem
      color: $primary-invert
</style>
